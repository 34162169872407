html {
  width: 100%;
  height: 100%;
  background: url(./img/pexels-dziana-hasanbekava-7063777.jpg);
  background-size: cover;
  background-position-y: 50%;
  background-repeat: no-repeat;
}

body {
  text-align: center;
  padding: 2em;
  margin: auto;
  position: absolute;
  bottom: 0;

  font-family: Verdana, Geneva, Tahoma, sans-serif;

}

#wrapper {
  background: #fff;
  border-radius: 2em;
}
#wrapper a {
  /* text-decoration: none; */
  font-family: 'Courier New', Courier, monospace;
  color: #000;
  position: relative;
  top: -2em;
}
#dropzone {

  width:400px;
  height:300px;

  border-radius: 2em 2em  0.5em 0.5em;
}

#dropzone.dragging {
  animation: mymove 1s 1;
  background-color: #effd5f;
}

@keyframes mymove {
  from {background-color: white;}
  to {background-color:#effd5f;}
}

#dropzone .icon {
  background-image: url(./img/upload.png);
  background-size: 75px 75px;
  background-repeat: no-repeat;
  background-position: center;

  width:100%;
  height:150px;
}

#dropzone button {
  background: rgb(32,131,231);
  border: 0;
  cursor:pointer;
  border-radius: 0.5em;
  padding: 1em;
  color: #fff;
  font-family: Verdana, Geneva, Tahoma, sans-serif;

  font-weight: bold;
}